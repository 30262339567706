import { ROUTES } from "../constants";

export const metaList = {
  [ROUTES.HOME]: {
    en: {
      title: "Home",
      desc: 'Support our Ukrainian company in the quest to improve the dental health and quality of life of people around the world. Learn about our innovative project "Dental Bot Consultant". Help us finish our development and get bonuses for you and your loved ones.',
      keys: "Ukraine, Ukrainian business, Dentistry, Support Ukraine, Dentist's consultation",
      url: ROUTES.HOME,
      img: require("../assets/images/post.jpg"),
      favicon: require("../assets/images/header.jpg"),
    },
  },
};
