import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { ROUTES } from "./constants";
import MetaTags from "./SEO/meta-tags";
import FullPageLoader from "./components/full-page-loader/full-page-loader";

import "./App.scss";

const HomeLazy = lazy(() => import("./pages/home/home"));

const theme = createTheme({});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MetaTags baseUrlsProp={{ en: "https://donate.dentalbot.pro/" }} title="Dental Bot Donation" />
        <Suspense fallback={<FullPageLoader />}>
          <Routes>
            <Route index element={<HomeLazy />} />
            <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
